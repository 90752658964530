<template>
  <div class="container">
    <div class="query-title">
      <div class="demo-input-suffix">
        用户名称：
        <el-input placeholder="用户名称ID" class="input-tit" v-model="name">
        </el-input>
      </div>
      <div class="demo-input-suffix">
        用户手机号：
        <el-input placeholder="用户手机号" class="input-tit" v-model="phone">
        </el-input>
      </div>
      <div class="demo-input-suffix">
        <el-button type="primary" class="tit-button" @click="querys()">查 询</el-button>
        <el-button type="primary" class="tit-button" @click="reset()">重 置</el-button>
      </div>
      <div class="demo-input-suffix" style="width: 100%;">

        <p class="titname">会员注册列表</p>

        <!-- <el-button type="primary" class="tit-button" @click="addBtn">新 增</el-button> -->
      </div>
    </div>
    <div class="table-container">
      <el-table highlight-current-row :header-cell-style="{ background: 'rgba(113,183,255,0.4)', color: '#606266', }"
        :data="tabledata" :height="tableHeight" border style="width: 100%">
        <el-table-column type="index" align='center' width="55" label="序号"></el-table-column>
        <el-table-column prop="createTime" label="注册时间" align="center"></el-table-column>
        <el-table-column prop="phone" label="手机号码" align="center"></el-table-column>
        <el-table-column prop="name" label="姓名" align="center"></el-table-column>
        <el-table-column prop="workerTypeOne" label="身份" align="center"></el-table-column>
        <el-table-column prop="workerTypeTwo" label="群工种" align="center"></el-table-column>
        <el-table-column prop="place" label="籍贯" align="center">
          <template slot-scope="scope">
            <p>{{scope.row.placeProvince+scope.row.placeCity+scope.row.placeDistrict+scope.row.placeCounty}}</p>
          </template>

        </el-table-column>
        <el-table-column prop="scale" label="班组规模" align="center"></el-table-column>
        <el-table-column prop="groupSum" label="加群数量" align="center"></el-table-column>
        <el-table-column prop="flag" label="vip状态" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.flag==0">非会员</p>
            <p v-if="scope.row.flag==1">会员</p>
          </template>
        </el-table-column>
        <el-table-column prop="" label="操作" width="170" align="center">
          <template slot-scope="scope">
            <el-button class="tabBnt" v-if="scope.row.distribution==1" type="text" @click="pass(scope.row, scope.$index)">开通分销资格</el-button>
            <el-button class="tabBnt" v-if="scope.row.distribution==0" type="text" @click="refuse(scope.row, scope.$index)">取消分销资格</el-button>
          </template>
        </el-table-column>
      </el-table>

    </div>

    <div class="fye">
      <pagintion :ToTal="total" :pageNums="pagenums" @dsydata="DsyData" @dstdata="DstData" />
    </div>
  </div>
</template>
<script>
  import pagintion from "@/components/pagintion/pagintion.vue";
  import {
    groupusergetList,groupuserupdate
  } from "@/api/api";
  export default {
    name: "memberregistration",
    components: {
      pagintion,
    },
    props: {},
    data() {
      return {
        name: '',
        phone: '',
        place: '',
        scale: '',
        workerType: this.workerType,
        tabledata: [],
        tableHeight: window.innerHeight - 280,
        dialogData: [],
        pageNum: 1,
        pageSize: 20,

        total: null,
        pagenums: null,
        dialogVisible: false,
        title: "",
      };
    },
    computed: {},
    watch: {},
    created() {
      window.addEventListener('resize', this.getHeight)
    },
    destroyed() {
      window.removeEventListener('resize', this.getHeight)
    },
    beforeDestroy() {},
    mounted() {

      this.Datalist();
    },
    methods: {
      querys() {
        this.pageNum = 1
        this.pageSize = 20
        this.pagenums = 1;
        this.Datalist()
      },
      reset() {
        this.name = ''
        this.phone = ''
        this.place = ''
        this.scale = ''
        this.workerType = ''
        this.pageNum = 1
        this.pageSize = 20
        this.pagenums = 1;
        this.Datalist()
      },
      Backbtn() {
        this.$router.go(-1)
      },
      getHeight() {
        this.tableHeight = window.innerHeight - 280;
      },

      addBtn() {
        this.dialogData = [];
        this.dialogVisible = true;
        this.title = "新增";
      },
      Getcancel() {
        this.dialogVisible = false;
      },
      addeteRmine() {
        this.dialogVisible = false;
        this.Datalist();
      },
      DsyData(data) {
        this.pagenums = data.dsy;
        this.pageNum = data.dsy;
        this.Datalist()
      },
      DstData(data) {
        this.pageSize = data.dst;
        this.Datalist()
      },

      edit(row, index) {
        this.dialogVisible = true;
        this.dialogData = [row, "edit"];
        this.title = "编辑";
      },
      refuse(row,index){

          this.$confirm("此操作将关闭分销资格, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then((action) => {
            if (action === "confirm") {
              let params = row
               
           params.distribution = 1;
              groupuserupdate(params).then((res) => {
                if (res.status == 200) {
                  this.Datalist();
                  this.$message({
                    message: "已通过",
                    type: "success",
                  });
                } else {
                  this.$message({
                    message: res.message,
                    type: "success",
                  });
                }
              }).catch((err) => {
                this.$message.error("修改失败！");
              });
            }
          }).catch(() => {});

      },
      pass(row,index){
        this.$confirm("此操作将开通分销资格, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = row

           params.distribution = 0;

            groupuserupdate(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "已通过",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("修改失败！");
            });
          }
        }).catch(() => {});
      },
      deletes(row) {

        this.$confirm("此操作将永久删除, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then((action) => {
          if (action === "confirm") {
            let params = [row.id]

            eworkgroupdelete(params).then((res) => {
              if (res.status == 200) {
                this.Datalist();
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.message,
                  type: "success",
                });
              }
            }).catch((err) => {
              this.$message.error("删除失败！");
            });
          }
        }).catch(() => {});

      },

      Datalist() {
        let params = {
          name: this.name,
          phone: this.phone,
          place: this.place,
          scale: this.scale,
          workerType: this.workerType,
          size: this.pageSize,
          current: this.pageNum,
        }
        groupusergetList(params).then((res) => {
          console.log(res)

          this.tabledata = res.data.list;
          //this.tabledata = res.data;
          this.total = Number(res.data.total);

        }).catch((error) => {
          this.$message.error("获取数据失败！");
        });
      },
    },
  };
</script>

<style scoped>

</style>
